<template>
  <div v-frag>
    <custom-alert/>
    <upcoming-elections
      :loading='loadingUpcoming'
      @set-vote-loader='setUpcomingRequestLoader'
    />
  </div>
</template>

<script>
import UpcomingElections from './components/UpcomingElections'
import CustomAlert from './components/CustomAlert'
// import VolunteerVoteRequest from './components/VolunteerVoteRequest'

export default {
  name: 'MyVoting',
  components: {
    CustomAlert,
    UpcomingElections
  },
  data() {
    return {
      loadingUpcoming: false
    }
  },
  methods: {
    setUpcomingRequestLoader(value) {
      this.loadingUpcoming = value
    }
  }
}
</script>

<style scoped>

</style>