<template>
  <v-dialog
    v-model='isOpen'
    max-width='500px'
    persistent
  >
    <v-card class='d-flex flex-column align-start pa-2'>
      <v-card-title class='text-center pb-4 mb-0'>Schedule Voting Day</v-card-title>
      <v-radio-group
        v-model='selectedDate'
        class='ml-6'
      >
        <v-radio
          v-for='date in requestItem.availableDates'
          :key='date'
          :label='date'
          :value='date'
          :disabled='requestItem.scheduledDate === date'
          class='pb-2'
        >
          {{ date }}
        </v-radio>
      </v-radio-group>
      <v-card-actions class='align-self-center'>
        <v-spacer></v-spacer>
        <v-btn
          class='px-14 mx-4 font-weight-bold white--text'
          color='primary'
          large
          outlined
          @click='closeDialog'
        >
          Cancel
        </v-btn>
        <v-btn
          :disabled='!selectedDate'
          :loading='loadingSubmit'
          class='px-14 mx-4 font-weight-bold white--text'
          color='primary'
          large
          @click=submitVotingRequest()
        >
          Schedule
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'ScheduleUpcomingElectionTimeDialog',
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    requestItem: {
      type: Object
    }
  },
  data() {
    return {
      selectedDate: '',
      loadingSubmit: false
    }
  },
  computed: {
    ...mapState({
      employeeId: state => state.employeeStore.employeeId
    })
  },
  methods: {
    ...mapActions({
      submitVotingRequests: 'employeeStore/submitVotingRequests'
    }),
    async submitVotingRequest() {
      this.loadingSubmit = true
      await this.submitVotingRequests({
        electionId: this.requestItem.id,
        voteDate: this.selectedDate,
        employeeId: [this.employeeId]
      })
      this.loadingSubmit = false
      this.closeDialog()
      this.$emit('updateData')
    },
    closeDialog() {
      this.selectedDate = ''
      this.$emit('closeDialog')
    }
  }
}
</script>

<style scoped>

</style>