<template>
  <v-dialog persistent v-model='isOpen' max-width='500px'>
    <v-card class='pa-2 wrap-card'>
      <v-card-title class='text-center mb-5'>
        Election Key Dates
      </v-card-title>
      <v-simple-table
        v-if='keyDates.length'
        class='px-4 mx-0'
        width='100%'
      >
        <template #default>
          <thead>
          <tr>
            <th class='text-left'>Date</th>
            <th class='text-left'>Description</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for='date in keyDates' :key='date.id'>
            <td>{{ date.date }}</td>
            <td><span :class="{ gray: date.isPastDate  }">{{ date.description }}</span></td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>

      <p v-else class='secondary--text text-center'>
        There is no available information about this campaign yet.
      </p>
      <v-card-actions class='d-flex flex-row justify-center mt-4'>
        <v-btn
          class='px-14 mx-4 font-weight-bold'
          color='primary'
          large
          outlined
          @click="$emit('closeDialog')"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'CurrentElectionsDialogs',
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    keyDates: {
      type: Array
    }
  }
}
</script>

<style scoped>
.wrap-card{
  border-radius: 10px;
}
.gray{
  opacity: .3;
}
</style>