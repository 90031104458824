<template>
  <div v-frag>
    <v-card
      v-if="!employeeProfile.ineligible && isModuleActive('voting')"
      class='d-flex flex-column rounded-lg elevation-3 ma-5 pb-5'
      max-width='100%'
    >
      <v-card-title class='headline font-weight-bold'>
        {{ sections.upcoming_elections || 'Elections' }}
        <!-- Змінити в CMS ключ!!! -->
      </v-card-title>
      <v-data-table
        :headers='headers'
        :items='upcomingElections'
        :loading='loadingElections || loading'
        class='mx-6 centered'
        no-data-text='There are currently no upcoming elections.'
        hide-default-footer
      >
        <template #item.status={item}>
          <span class='text-capitalize' :class='statusColor(item.status)'>{{ item.status }}</span>
        </template>
        <template #votingDay={item}>
          <span v-if='item.status==="voted"' class='text-capitalize'>
            {{ item.votingDay }}
          </span>
        </template>
        <template #item.schedule={item}>
          <v-btn
            color='primary'
            class='text-capitalize'
            v-if='filteredUpcomingElections(item) && (!item.isVoted && !item.isVoteDatePast)'
            @click='openDatePickerDialog(item)'
          >
            <span v-if='item.schedule'>Reschedule</span>
            <span v-else>Schedule</span>
          </v-btn>
        </template>
        <template #item.voterGuide={item}>
          <v-btn
            v-if='!employeeProfile.isHiddenVoterGuide'
            color='primary'
            class='text-capitalize'
            @click=viewVoterGuide(item)
          >
            Voter Guide
          </v-btn>
        </template>
        <template #item.keyDates={item}>
          <v-btn
            color='primary'
            class='text-capitalize'
            @click='openKeyDatesModal(item)'
          >
            Key Dates
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <ScheduleUpcomingElectionTimeDialog
      :isOpen='isDayOffDialogOpen'
      :requestItem='requestItem'
      @closeDialog='isDayOffDialogOpen = false'
      @updateData='updateData'
    />
    <KeyDatesDialog
      :isOpen='isDialogKeyDatesOpen'
      :keyDates='electionKeyDates'
      @closeDialog='isDialogKeyDatesOpen = false'
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import ScheduleUpcomingElectionTimeDialog from '@/views/employee/components/ScheduleUpcomingElectionTimeDialog.vue'
import KeyDatesDialog from './KeyDatesDialog'

export default {
  name: 'UpcomingElections',
  components: {
    KeyDatesDialog,
    ScheduleUpcomingElectionTimeDialog
  },
  data: () => ({
    requestItem: {},
    disabledSeeMore: true,
    isDayOffDialogOpen: false,
    isDialogKeyDatesOpen: false,
    electionKeyDates: [],
    loadingElections: false,
    loadingSubmit: false,
    headers: [
      { text: 'Election Name', align: 'start', value: 'description' },
      { text: 'Status', align: 'start', value: 'status' },
      { text: 'Scheduled Voting Day', align: 'start', value: 'scheduledDate' },
      { text: 'Voting Day', align: 'start', value: 'votingDay' },
      { text: '', align: 'start', value: 'voterGuide', sortable: false, width: '10%' },
      { text: '', align: 'start', value: 'schedule', sortable: false, width: '10%' },
      { text: '', align: 'start', value: 'keyDates', sortable: false, width: '10%' }
    ]
  }),
  props: {
    loading: {
      default: false,
      type: Boolean
    }
  },
  async created() {
    this.loadingElections = true
    this.SET_UPCOMING_ELECTIONS([])
    await this.getModules()
    await this.getEmployeeId()
    await this.getEmployeeProfile()
    await this.getElections(this.employeeId)
    this.loadingElections = false
    this.disabledSeeMore = false
  },
  computed: {
    ...mapGetters('employeeStore', ['isModuleActive']),
    ...mapState({
      employeeId: state => state.employeeStore.employeeId,
      employeeProfile: state => state.employeeStore.employeeProfile,
      sections: state => state.cmsStore.sections,
      upcomingElections: state => state.employeeStore.upcomingElections
    })
  },
  methods: {
    ...mapActions('employeeStore', ['getEmployeeId', 'getEmployeeProfile', 'getModules', 'getElections']),
    ...mapMutations('employeeStore', ['SET_CUSTOM_ALERT_DATA', 'SET_UPCOMING_ELECTIONS']),
    statusColor(status) {
      let color
      switch (status) {
        case 'Voted':
          color = 'success--text'
          break
        case 'Scheduled':
          color = ''
          break
        case 'Not Scheduled':
          color = 'red--text'
      }
      return color
    },
    async openKeyDatesModal(item) {
      this.electionKeyDates = item.keyDates || []
      this.isDialogKeyDatesOpen = true
    },
    openDatePickerDialog(item) {
      const arr = this.getDateArray(
        item.start,
        item.end,
        item.earlyVoteStartDate,
        item.earlyVoteEndDate,
        item.voteDate,
        item.firstWeekEndDate
      )
      item = { ...item, availableDates: arr }
      this.isDayOffDialogOpen = true
      this.requestItem = item
    },
    getDateArray(start, end, earlyStart, earlyEnd, voteDate, firstWeek) {
      let dateArray = []
      if (new Date(Date.now()).getTime() <= new Date(firstWeek).getTime()) {
        let dates = this.getDaysArray(earlyStart, firstWeek).map(el => this.formatDate(el))
        dateArray = [...dates]
      } else {
        if (earlyStart && earlyEnd) {
          let dates = this.getDaysArray(earlyStart, earlyEnd).map(el => this.formatDate(el))
          dateArray = [...dates]
        }
        if (voteDate) dateArray.push(this.formatDate(new Date(voteDate)))
        if (!dateArray) {
          const dates = this.getDaysArray(start, end).map(el => this.formatDate(el))
          dateArray = [...dates]
        }
      }
      return Array.from(new Set(dateArray.filter(el => new Date(new Date(el).setMilliseconds(6 * 3600 * 1000)) >= new Date(Date.now()).setHours(0,0,0,0))))
    },
    getDaysArray(start, end) {
      let startDate = new Date(start)
      startDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate())
      let endDate = new Date(end)
      endDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate())
      let datesArr = []
      for (let dt = startDate; dt <= endDate; dt.setDate(dt.getDate() + 1)) {
        datesArr.push(new Date(dt))
      }
      return datesArr
    },
    formatDate(date) {
      let year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date)
      let month = new Intl.DateTimeFormat('en', { month: 'short' }).format(date)
      let day = new Intl.DateTimeFormat('en', { day: 'numeric' }).format(date)
      return `${ month } ${ day }, ${ year }`
    },
    async updateData() {
      this.loadingElections = true
      this.$emit('set-vote-loader', true)
      await this.getElections(this.employeeId)
      this.loadingElections = false
      this.$emit('set-vote-loader', false)
    },
    viewVoterGuide(item) {
      this.$router.push({ name: 'VotersGuideEmployee', params: { employeeId: this.employeeId } })
      this.$store.dispatch('election', item)
    },
    filteredUpcomingElections(election) {
      if (!election.runoff) {
        return true
      }
      let item = this.employeeProfile.voterGuidesByElection
        .filter(item => {return item.id === election.id})
      return item[0].voterGuide

      // return this.upcomingElections.filter(item => {
      //   return !(runoffElections.includes(item.id) && !this.employeeProfile.voterGuidesByElection.find(el => el.id === item.id).voterGuide);
      // })
    }
  }
}
</script>

<style lang='scss'>
.centered {
  i {
    position: absolute !important;
  }
}
</style>