var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"frag",rawName:"v-frag"}]},[(!_vm.employeeProfile.ineligible && _vm.isModuleActive('voting'))?_c('v-card',{staticClass:"d-flex flex-column rounded-lg elevation-3 ma-5 pb-5",attrs:{"max-width":"100%"}},[_c('v-card-title',{staticClass:"headline font-weight-bold"},[_vm._v(" "+_vm._s(_vm.sections.upcoming_elections || 'Elections')+" ")]),_c('v-data-table',{staticClass:"mx-6 centered",attrs:{"headers":_vm.headers,"items":_vm.upcomingElections,"loading":_vm.loadingElections || _vm.loading,"no-data-text":"There are currently no upcoming elections.","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-capitalize",class:_vm.statusColor(item.status)},[_vm._v(_vm._s(item.status))])]}},{key:"votingDay",fn:function(ref){
var item = ref.item;
return [(item.status==="voted")?_c('span',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(item.votingDay)+" ")]):_vm._e()]}},{key:"item.schedule",fn:function(ref){
var item = ref.item;
return [(_vm.filteredUpcomingElections(item) && (!item.isVoted && !item.isVoteDatePast))?_c('v-btn',{staticClass:"text-capitalize",attrs:{"color":"primary"},on:{"click":function($event){return _vm.openDatePickerDialog(item)}}},[(item.schedule)?_c('span',[_vm._v("Reschedule")]):_c('span',[_vm._v("Schedule")])]):_vm._e()]}},{key:"item.voterGuide",fn:function(ref){
var item = ref.item;
return [(!_vm.employeeProfile.isHiddenVoterGuide)?_c('v-btn',{staticClass:"text-capitalize",attrs:{"color":"primary"},on:{"click":function($event){return _vm.viewVoterGuide(item)}}},[_vm._v(" Voter Guide ")]):_vm._e()]}},{key:"item.keyDates",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"text-capitalize",attrs:{"color":"primary"},on:{"click":function($event){return _vm.openKeyDatesModal(item)}}},[_vm._v(" Key Dates ")])]}}],null,false,1983212267)})],1):_vm._e(),_c('ScheduleUpcomingElectionTimeDialog',{attrs:{"isOpen":_vm.isDayOffDialogOpen,"requestItem":_vm.requestItem},on:{"closeDialog":function($event){_vm.isDayOffDialogOpen = false},"updateData":_vm.updateData}}),_c('KeyDatesDialog',{attrs:{"isOpen":_vm.isDialogKeyDatesOpen,"keyDates":_vm.electionKeyDates},on:{"closeDialog":function($event){_vm.isDialogKeyDatesOpen = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }